<section
  id="consulting"
  class="bg-black d-flex min-vh-100 justify-content-center align-items-center"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto text-center">
        <h1>Consulting</h1>
        <p>Do you need help with AI or Automation? I am happy to help!</p>
        <div>
          <a href="#contact" class="btn btn-lg btn-outline-primary mx-2">
            <i class="fas fa-envelope"></i>&nbsp;Contact
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/in/kurzer/"
            class="btn btn-lg btn-outline-primary"
          >
            <i class="fab fa-linkedin-in"></i>&nbsp;LinkedIn
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
