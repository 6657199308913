import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpParamsOptions,
} from '@angular/common/http';

export interface ContactFormData {
  [key: string]: string;
}

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(private http: HttpClient) {}

  sendContactForm(formData: ContactFormData) {
    let params = new HttpParams({ fromObject: formData });
    const url =
      'https://sendcontactform-3kkss2tbxa-uc.a.run.app';
    return this.http.get(url, { params: params });
  }
}
