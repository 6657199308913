<header>
  <!-- Fixed navbar -->
  <nav
    id="navbar"
    class="navbar navbar-expand-md navbar-dark bg-black-nav fixed-top"
    [ngClass]="{ scrolled: scrolled }"
  >
    <div class="container-fluid">
      <a class="navbar-brand" href="#">KK</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav me-auto mb-2 mb-md-0">
          <li class="nav-item">
            <a class="nav-link" href="#about">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#research">Research</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#consulting">Consulting</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#photography">Photography</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#contact">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
