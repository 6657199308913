<!-- <app-video-cover></app-video-cover> -->
<app-picture-cover></app-picture-cover>
<app-about class="bg-black"></app-about>

<!-- <picture>
  <img appIntersectionObserver intersectionClass="img-div" nonIntersectionClass="img-div grayscale"
    sizes="(max-width: 3840px) 100vw, 3840px" srcset="
../../assets/img/Monte_Rosa_Seen_from_the_Summit_of_Rimpfischhorn_exwf1s_c_scale,w_1080.jpg 1080w,
../../assets/img/Monte_Rosa_Seen_from_the_Summit_of_Rimpfischhorn_exwf1s_c_scale,w_1417.jpg 1417w,
../../assets/img/Monte_Rosa_Seen_from_the_Summit_of_Rimpfischhorn_exwf1s_c_scale,w_1704.jpg 1704w,
../../assets/img/Monte_Rosa_Seen_from_the_Summit_of_Rimpfischhorn_exwf1s_c_scale,w_1960.jpg 1960w,
../../assets/img/Monte_Rosa_Seen_from_the_Summit_of_Rimpfischhorn_exwf1s_c_scale,w_2201.jpg 2201w,
../../assets/img/Monte_Rosa_Seen_from_the_Summit_of_Rimpfischhorn_exwf1s_c_scale,w_2407.jpg 2407w,
../../assets/img/Monte_Rosa_Seen_from_the_Summit_of_Rimpfischhorn_exwf1s_c_scale,w_2612.jpg 2612w,
../../assets/img/Monte_Rosa_Seen_from_the_Summit_of_Rimpfischhorn_exwf1s_c_scale,w_2799.jpg 2799w,
../../assets/img/Monte_Rosa_Seen_from_the_Summit_of_Rimpfischhorn_exwf1s_c_scale,w_2992.jpg 2992w,
../../assets/img/Monte_Rosa_Seen_from_the_Summit_of_Rimpfischhorn_exwf1s_c_scale,w_3169.jpg 3169w,
../../assets/img/Monte_Rosa_Seen_from_the_Summit_of_Rimpfischhorn_exwf1s_c_scale,w_3343.jpg 3343w,
../../assets/img/Monte_Rosa_Seen_from_the_Summit_of_Rimpfischhorn_exwf1s_c_scale,w_3504.jpg 3504w,
../../assets/img/Monte_Rosa_Seen_from_the_Summit_of_Rimpfischhorn_exwf1s_c_scale,w_3658.jpg 3658w,
../../assets/img/Monte_Rosa_Seen_from_the_Summit_of_Rimpfischhorn_exwf1s_c_scale,w_3810.jpg 3810w,
../../assets/img/Monte_Rosa_Seen_from_the_Summit_of_Rimpfischhorn_exwf1s_c_scale,w_3833.jpg 3833w,
../../assets/img/Monte_Rosa_Seen_from_the_Summit_of_Rimpfischhorn_exwf1s_c_scale,w_3839.jpg 3839w,
../../assets/img/Monte_Rosa_Seen_from_the_Summit_of_Rimpfischhorn_exwf1s_c_scale,w_3840.jpg 3840w"
    src="Monte_Rosa_Seen_from_the_Summit_of_Rimpfischhorn_exwf1s_c_scale,w_3840.jpg"
    alt="Monte Rosa Seen from the Summit of Rimpfischhorn">
</picture> -->

<picture>
  <img appIntersectionObserver intersectionClass="img-div" nonIntersectionClass="img-div grayscale"
    sizes="(max-width: 3840px) 100vw, 3840px" srcset="
../../assets/img/South_Faces_of_the_Churfirsten_from_Above_elfczl_c_scale,w_1080.jpg 1080w,
../../assets/img/South_Faces_of_the_Churfirsten_from_Above_elfczl_c_scale,w_1341.jpg 1341w,
../../assets/img/South_Faces_of_the_Churfirsten_from_Above_elfczl_c_scale,w_1568.jpg 1568w,
../../assets/img/South_Faces_of_the_Churfirsten_from_Above_elfczl_c_scale,w_1775.jpg 1775w,
../../assets/img/South_Faces_of_the_Churfirsten_from_Above_elfczl_c_scale,w_1962.jpg 1962w,
../../assets/img/South_Faces_of_the_Churfirsten_from_Above_elfczl_c_scale,w_2140.jpg 2140w,
../../assets/img/South_Faces_of_the_Churfirsten_from_Above_elfczl_c_scale,w_2314.jpg 2314w,
../../assets/img/South_Faces_of_the_Churfirsten_from_Above_elfczl_c_scale,w_2478.jpg 2478w,
../../assets/img/South_Faces_of_the_Churfirsten_from_Above_elfczl_c_scale,w_2625.jpg 2625w,
../../assets/img/South_Faces_of_the_Churfirsten_from_Above_elfczl_c_scale,w_2790.jpg 2790w,
../../assets/img/South_Faces_of_the_Churfirsten_from_Above_elfczl_c_scale,w_2926.jpg 2926w,
../../assets/img/South_Faces_of_the_Churfirsten_from_Above_elfczl_c_scale,w_3070.jpg 3070w,
../../assets/img/South_Faces_of_the_Churfirsten_from_Above_elfczl_c_scale,w_3207.jpg 3207w,
../../assets/img/South_Faces_of_the_Churfirsten_from_Above_elfczl_c_scale,w_3344.jpg 3344w,
../../assets/img/South_Faces_of_the_Churfirsten_from_Above_elfczl_c_scale,w_3616.jpg 3616w,
../../assets/img/South_Faces_of_the_Churfirsten_from_Above_elfczl_c_scale,w_3737.jpg 3737w,
../../assets/img/South_Faces_of_the_Churfirsten_from_Above_elfczl_c_scale,w_3827.jpg 3827w,
../../assets/img/South_Faces_of_the_Churfirsten_from_Above_elfczl_c_scale,w_3798.jpg 3798w,
../../assets/img/South_Faces_of_the_Churfirsten_from_Above_elfczl_c_scale,w_3840.jpg 3840w"
    src="../../assets/img/South_Faces_of_the_Churfirsten_from_Above_elfczl_c_scale,w_3840.jpg"
    alt="South Faces of the Churfirsten from Above">
</picture>

<app-research></app-research>
<picture>
  <!-- <img
      appIntersectionObserver
      intersectionClass="img-div"
      nonIntersectionClass="img-div grayscale"
      sizes="(max-width: 3840px) 100vw, 3840px"
      srcset="
        ../../assets/img/Mischabel_Range_before_Sunrise_v4rdxw_c_scale,w_2629.jpg 2629w,
        ../../assets/img/Mischabel_Range_before_Sunrise_v4rdxw_c_scale,w_3270.jpg 3270w,
        ../../assets/img/Mischabel_Range_before_Sunrise_v4rdxw_c_scale,w_3763.jpg 3763w,
        ../../assets/img/Mischabel_Range_before_Sunrise_v4rdxw_c_scale,w_3828.jpg 3828w,
        ../../assets/img/Mischabel_Range_before_Sunrise_v4rdxw_c_scale,w_3840.jpg 3840w
      "
      src="../../assets/img/Mischabel_Range_before_Sunrise_v4rdxw_c_scale,w_3840.jpg"
      alt="Mischabel Range before Sunrise"
    /> -->
  <!-- <img appIntersectionObserver intersectionClass="img-div" nonIntersectionClass="img-div grayscale"
    sizes="(max-width: 3840px) 100vw, 3840px" srcset="
../../assets/img/Strahlhorn_Waking_Up_z7gch9_c_scale,w_1080.jpg 1080w,
../../assets/img/Strahlhorn_Waking_Up_z7gch9_c_scale,w_1708.jpg 1708w,
../../assets/img/Strahlhorn_Waking_Up_z7gch9_c_scale,w_2225.jpg 2225w,
../../assets/img/Strahlhorn_Waking_Up_z7gch9_c_scale,w_2648.jpg 2648w,
../../assets/img/Strahlhorn_Waking_Up_z7gch9_c_scale,w_3042.jpg 3042w,
../../assets/img/Strahlhorn_Waking_Up_z7gch9_c_scale,w_3410.jpg 3410w,
../../assets/img/Strahlhorn_Waking_Up_z7gch9_c_scale,w_3696.jpg 3696w,
../../assets/img/Strahlhorn_Waking_Up_z7gch9_c_scale,w_3839.jpg 3839w,
../../assets/img/Strahlhorn_Waking_Up_z7gch9_c_scale,w_3840.jpg 3840w"
    src="../../assets/img/Strahlhorn_Waking_Up_z7gch9_c_scale,w_3840.jpg" alt="Strahlhorn Waking Up"> -->
  <img appIntersectionObserver intersectionClass="img-div" nonIntersectionClass="img-div grayscale"
    sizes="(max-width: 3840px) 100vw, 3840px" srcset="
    ../../assets/img/Dawn_high_above_the_Matter_Valley_aqeive_c_scale,w_1080.jpg 1080w,
    ../../assets/img/Dawn_high_above_the_Matter_Valley_aqeive_c_scale,w_1450.jpg 1450w,
    ../../assets/img/Dawn_high_above_the_Matter_Valley_aqeive_c_scale,w_1771.jpg 1771w,
    ../../assets/img/Dawn_high_above_the_Matter_Valley_aqeive_c_scale,w_2069.jpg 2069w,
    ../../assets/img/Dawn_high_above_the_Matter_Valley_aqeive_c_scale,w_2317.jpg 2317w,
    ../../assets/img/Dawn_high_above_the_Matter_Valley_aqeive_c_scale,w_2528.jpg 2528w,
    ../../assets/img/Dawn_high_above_the_Matter_Valley_aqeive_c_scale,w_2766.jpg 2766w,
    ../../assets/img/Dawn_high_above_the_Matter_Valley_aqeive_c_scale,w_2997.jpg 2997w,
    ../../assets/img/Dawn_high_above_the_Matter_Valley_aqeive_c_scale,w_3206.jpg 3206w,
    ../../assets/img/Dawn_high_above_the_Matter_Valley_aqeive_c_scale,w_3396.jpg 3396w,
    ../../assets/img/Dawn_high_above_the_Matter_Valley_aqeive_c_scale,w_3582.jpg 3582w,
    ../../assets/img/Dawn_high_above_the_Matter_Valley_aqeive_c_scale,w_3775.jpg 3775w,
    ../../assets/img/Dawn_high_above_the_Matter_Valley_aqeive_c_scale,w_3811.jpg 3811w,
    ../../assets/img/Dawn_high_above_the_Matter_Valley_aqeive_c_scale,w_3840.jpg 3840w"
    src="../../assets/img/Dawn_high_above_the_Matter_Valley_aqeive_c_scale,w_3840.jpg"
    alt="Dawn High above the Matter Valey">




</picture>

<app-consulting></app-consulting>
<picture>
  <!-- <img
    appIntersectionObserver
    intersectionClass="img-div"
    nonIntersectionClass="img-div grayscale"
    sizes="(max-width: 3840px) 100vw, 3840px"
    srcset="
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_1424.jpg 1424w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_1707.jpg 1707w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_1966.jpg 1966w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_2192.jpg 2192w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_2427.jpg 2427w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_2605.jpg 2605w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_2798.jpg 2798w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_2970.jpg 2970w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_3145.jpg 3145w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_3302.jpg 3302w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_3463.jpg 3463w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_3622.jpg 3622w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_3779.jpg 3779w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_3829.jpg 3829w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_3840.jpg 3840w
    "
    src="../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_3840.jpg"
    alt="Drachensee and Ehrwalder Sonnenspitze"
  /> -->
  <img appIntersectionObserver intersectionClass="img-div" nonIntersectionClass="img-div grayscale"
    sizes="(max-width: 3840px) 100vw, 3840px" srcset="
  ../../assets/img/Pointe_d_Aveneyre_Rising_from_the_Clouds_ga6qy5_c_scale,w_1000.jpg 1000w,
  ../../assets/img/Pointe_d_Aveneyre_Rising_from_the_Clouds_ga6qy5_c_scale,w_1559.jpg 1559w,
  ../../assets/img/Pointe_d_Aveneyre_Rising_from_the_Clouds_ga6qy5_c_scale,w_1990.jpg 1990w,
  ../../assets/img/Pointe_d_Aveneyre_Rising_from_the_Clouds_ga6qy5_c_scale,w_2295.jpg 2295w,
  ../../assets/img/Pointe_d_Aveneyre_Rising_from_the_Clouds_ga6qy5_c_scale,w_2632.jpg 2632w,
  ../../assets/img/Pointe_d_Aveneyre_Rising_from_the_Clouds_ga6qy5_c_scale,w_2963.jpg 2963w,
  ../../assets/img/Pointe_d_Aveneyre_Rising_from_the_Clouds_ga6qy5_c_scale,w_3259.jpg 3259w,
  ../../assets/img/Pointe_d_Aveneyre_Rising_from_the_Clouds_ga6qy5_c_scale,w_3542.jpg 3542w,
  ../../assets/img/Pointe_d_Aveneyre_Rising_from_the_Clouds_ga6qy5_c_scale,w_3801.jpg 3801w,
  ../../assets/img/Pointe_d_Aveneyre_Rising_from_the_Clouds_ga6qy5_c_scale,w_3825.jpg 3825w,
  ../../assets/img/Pointe_d_Aveneyre_Rising_from_the_Clouds_ga6qy5_c_scale,w_3840.jpg 3840w"
    src="../../assets/img/Pointe_d_Aveneyre_Rising_from_the_Clouds_ga6qy5_c_scale,w_3840.jpg"
    alt="Pointe D’Aveneyre Rising from the Clouds">
</picture>
<!-- <picture>
  <img
    appIntersectionObserver
    intersectionClass="img-div"
    nonIntersectionClass="img-div grayscale"
    sizes="(max-width: 3840px) 100vw, 3840px"
    srcset="
      ../../assets/img/Bishorn_wiufna_c_scale,w_1557.jpg 1557w,
      ../../assets/img/Bishorn_wiufna_c_scale,w_1886.jpg 1886w,
      ../../assets/img/Bishorn_wiufna_c_scale,w_2163.jpg 2163w,
      ../../assets/img/Bishorn_wiufna_c_scale,w_2403.jpg 2403w,
      ../../assets/img/Bishorn_wiufna_c_scale,w_2626.jpg 2626w,
      ../../assets/img/Bishorn_wiufna_c_scale,w_2819.jpg 2819w,
      ../../assets/img/Bishorn_wiufna_c_scale,w_3014.jpg 3014w,
      ../../assets/img/Bishorn_wiufna_c_scale,w_3201.jpg 3201w,
      ../../assets/img/Bishorn_wiufna_c_scale,w_3384.jpg 3384w,
      ../../assets/img/Bishorn_wiufna_c_scale,w_3544.jpg 3544w,
      ../../assets/img/Bishorn_wiufna_c_scale,w_3705.jpg 3705w,
      ../../assets/img/Bishorn_wiufna_c_scale,w_3837.jpg 3837w,
      ../../assets/img/Bishorn_wiufna_c_scale,w_3839.jpg 3839w,
      ../../assets/img/Bishorn_wiufna_c_scale,w_3840.jpg 3840w
    "
    src="../../assets/img/Bishorn_wiufna_c_scale,w_3840.jpg"
    alt="Bishorn"
  />
</picture> -->

<app-photography></app-photography>
<picture>
  <img appIntersectionObserver intersectionClass="img-div" nonIntersectionClass="img-div grayscale"
    sizes="(max-width: 3840px) 100vw, 3840px" srcset="
      ../../assets/img/Piz_Pal%C3%BC_at_Dusk_mgko1c_c_scale,w_1827.jpg 1827w,
      ../../assets/img/Piz_Pal%C3%BC_at_Dusk_mgko1c_c_scale,w_2224.jpg 2224w,
      ../../assets/img/Piz_Pal%C3%BC_at_Dusk_mgko1c_c_scale,w_2623.jpg 2623w,
      ../../assets/img/Piz_Pal%C3%BC_at_Dusk_mgko1c_c_scale,w_2952.jpg 2952w,
      ../../assets/img/Piz_Pal%C3%BC_at_Dusk_mgko1c_c_scale,w_3258.jpg 3258w,
      ../../assets/img/Piz_Pal%C3%BC_at_Dusk_mgko1c_c_scale,w_3547.jpg 3547w,
      ../../assets/img/Piz_Pal%C3%BC_at_Dusk_mgko1c_c_scale,w_3827.jpg 3827w,
      ../../assets/img/Piz_Pal%C3%BC_at_Dusk_mgko1c_c_scale,w_3840.jpg 3840w
    " src="../../assets/img/Piz_Pal%C3%BC_at_Dusk_mgko1c_c_scale,w_3840.jpg" alt="Piz Palü at Dusk" />
</picture>

<app-contact></app-contact>