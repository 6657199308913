<head>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title>Recipes</title>
  <style>
    .recipe {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ccc;
      padding: 20px 0;
    }

    .instructions,
    .ingredients {
      width: 48%;
    }

    h2,
    h3 {
      margin-top: 0;
    }
  </style>
</head>

<body>
  <!-- horizontally aligned h1 using bootstrap -->
  <h1 class="text-center">Favorite Recipes</h1>

  <div class="recipe">
    <div class="instructions">
      <h2>Zitronen-Zucchini-Kuchen</h2>
      <p><strong>Einfach</strong></p>
      <ol>
        <li>
          Zucchini raspeln, in ein Sieb geben und mit dem Salz durch-mischen,
          damit sie etwas Wasser abgeben. 10 Minuten stehen lassen.
        </li>
        <li>
          Den Backofen auf 150°C vorheizen. Für den Teig Mehl, Speise-stärke,
          Backpulver und Natron in eine Schüssel sieben.
        </li>
        <li>
          In einer zweiten Schüssel die Butter mit den Rührbesen des
          Handrührgerätes auf höchster Stufe schaumig schlagen und den Zucker
          zugeben. Die Eier einzeln gut unterschlagen. Zitronensaft und -schale
          zugeben.
        </li>
        <li>
          Den Mixer herunterschalten und abwechselnd den Frischkäse und die
          Mehlmischung einrühren, bis sich alles gut vermischt hat.
        </li>
        <li>
          Die Zucchini auf Küchenpapier abtrocknen und mit einem Teig-spatel von
          Hand unter den Teig heben. Den Teig in die gebutterte Kastenform
          füllen und 60 Minuten auf der mittleren Schiene bei 150°C backen.
        </li>
        <li>
          Mit einem Holzstäbchen am Ende der Backzeit testen, ob der Kuchen gar
          ist. Wenn Sie es in den Kuchen stechen und wieder herausziehen, sollte
          kein Teigrest daran kleben bleiben.
        </li>
        <li>
          Den fertigen Kuchen etwa 15 Minuten auskühlen lassen, dann die Ränder
          mit einem Messer lösen und den Kuchen auf ein Ku-chengitter stürzen,
          vollständig abkühlen lassen.
        </li>
        <li>
          Für die Glasur die Butter in einem Topf schmelzen. Vom Herd nehmen und
          den Zitronensaft und den Puderzucker zugeben. Gut verrühren, etwas
          abkühlen lassen und den erkalteten Kuchen damit überziehen.
        </li>
      </ol>
    </div>
    <div class="ingredients">
      <h3>Zutaten:</h3>
      <ul>
        <li>1 Kastenform (24 cm Länge)</li>
        <li><strong>Für den Teig:</strong></li>
        <li>250 g Zucchini</li>
        <li>1 Prise Salz</li>
        <li>200 g Mehl</li>
        <li>50 g Speisestärke</li>
        <li>1 TL Backpulver</li>
        <li>1 TL Natron</li>
        <li>150 g weiche Butter, plus Butter für die Form</li>
        <li>250 g Zucker</li>
        <li>4 Eier</li>
        <li>60 ml frisch gepresster Zitronensaft</li>
        <li>Abgeriebene Schale von 1 unbehandelten Zitrone</li>
        <li>80 g Frischkäse</li>
        <li><strong>Für die Glasur:</strong></li>
        <li>150 g Butter</li>
        <li>8 EL frisch gepresster Zitronensaft</li>
        <li>250 g Puderzucker</li>
      </ul>
    </div>
  </div>

  <!-- ... Repeat for other recipes ... -->

  <div class="recipe">
    <div class="instructions">
      <h2>Apfelkuchen mit Rosmarin</h2>
      <p><strong>Einfach</strong></p>
      <ol>
        <li>
          Die Äpfel schälen, vierteln, das Kerngehäuse entfernen und die
          Apfelstücke in Würfel schneiden.
        </li>
        <li>
          Die Butter in einer Pfanne zerlassen und die Apfelwürfel mit dem
          Rosmarin und dem Apfelsaft bei mittlerer Hitze 5 Minuten dünsten.
          Zwischendurch ein paarmal umrühren.
        </li>
        <li>
          Die Apfelmasse mit 1 EL Zucker bestreuen und noch 1-2 Minuten
          weiterdünsten. Beiseite stellen. Den Backofen auf 150 °C vorheizen.
        </li>
        <li>
          Für den Teig Mehl, Backpulver, Natron, Zimt und Salz in eine Schüssel
          sieben.
        </li>
        <li>
          Die Butter mit den Rührquirlen des Handrührgeräts in einer zweiten
          Schüssel auf höchster Stufe schaumig schlagen. Zuerst den Zucker und
          dann die Melasse unter die Butter rühren. Die Eier nacheinander
          kräftig unterschlagen.
        </li>
        <li>
          Die Geschwindigkeit des Rührgeräts verringern und abwechselnd etwas
          von der Mehlmischung und dem Sauerrahm zur But-ter-Ei-Masse geben.
          Weiterrühren, bis der Teig ganz glatt ist.
        </li>
        <li>
          Die Apfelmischung mit einem Teigschaber unter den Teig heben. Die
          Teigmasse in die gebutterte Springform füllen und bei 150°C etwa 60
          Minuten auf der mittleren Schiene backen.
        </li>
        <li>
          Den fertigen Kuchen 10 Minuten in der Form auskühlen lassen, dann die
          Springform behutsam entfernen und den Kuchen auf ein Kuchengitter
          geben. Nach Belieben mit Puderzucker bestäuben.
        </li>
      </ol>
      <p>
        <strong>TIPP</strong> Mit frischer Schlagsahne schmeckt mir dieser
        Kuchen besonders gut.
      </p>
    </div>
    <div class="ingredients">
      <h3>Zutaten:</h3>
      <ul>
        <li>1 Springform (24 cm Ø)</li>
        <li><strong>Für die Apfelmischung:</strong></li>
        <li>2 säuerliche Äpfel (z. B. Boskop)</li>
        <li>2 EL Butter (30 g)</li>
        <li>3 TL Rosmarinnadeln, fein gehackt</li>
        <li>2 EL Apfelsaft</li>
        <li>1 EL Zucker</li>
        <li><strong>Für den Teig:</strong></li>
        <li>250 g Mehl</li>
        <li>1 TL Backpulver</li>
        <li>1 TL Natron</li>
        <li>½ TL gemahlener Zimt</li>
        <li>1 Prise Salz</li>
        <li>170 g weiche Butter, plus Butter für die Form</li>
        <li>170 g brauner Zucker</li>
        <li>1 EL Melasse</li>
        <li>3 Eier</li>
        <li>125 g Sauerrahm</li>
        <li>Puderzucker zum Bestäuben (nach Belieben)</li>
      </ul>
    </div>
  </div>

  <div class="recipe">
    <div class="instructions">
      <h2>Amaretto-Kirsch-Schnitten</h2>
      <p><strong>Ohne Creme superschnell zu backen</strong></p>
      <ol>
        <li>
          Die Kirschen in ein Sieb schütten und gut abtropfen lassen. Den
          Backofen auf 150°C vorheizen. Mehl, Salz und Backpulver in eine
          Schüssel sieben.
        </li>
        <li>
          Die Butter in einer zweiten Schüssel mit den Rührbesen des
          Handrührgerätes auf höchster Stufe schaumig schlagen, den Zucker
          dazugeben und weiterrühren. Die Eier nacheinander gut untermischen.
          Jedes Ei sollte mindestens 30 Sekunden eingeschlagen werden.
        </li>
        <li>
          Vanille und gemahlene Mandeln einrühren. Die Rührgeschwin-digkeit
          reduzieren und die Mehlmischung und den Amaretto nacheinander
          dazugeben und alles zu einer homogenen Masse verar-beiten. Erst die
          nicht zerkleinerten Amarettini und anschließend die Kirschen mit einem
          Teigschaber unterheben.
        </li>
        <li>
          Den Teig auf dem mit Backpapier ausgelegten Backblech vertei-len. Das
          Blech bei 150°C auf der mittleren Schiene in den Backofen schieben und
          den Kuchen 40 Minuten backen. Den fertigen Kuchen aus dem Ofen nehmen
          und abkühlen lassen.
        </li>
        <li>
          Für die Creme die Gelatine in etwas kaltem Wasser einweichen.
          Puderzucker und Eigelbe mit den Rührbesen des Handrührgerätes auf
          höchster Stufe schaumig schlagen. Den Mascarpone zugeben, die
          Rührgeschwindigkeit herunterschalten und die Creme glatt rühren.
        </li>
        <li>
          In einem kleine Topf den Amaretto erhitzen (nicht kochen), vom Herd
          nehmen und die ausgedrückte Gelatine darin auflösen. 2 EL der
          Mascarponecreme dazugeben und alles verrühren. Die Gelati-nemischung
          zur Creme geben und gut untermischen.
        </li>
        <li>
          Die Sahne steif schlagen und unter die Amarettocreme heben, sobald
          diese beginnt zu gelieren. Die Creme auf dem erkalteten Kuchen glatt
          streichen und das Backblech für 30 Minuten kalt stellen.
        </li>
      </ol>
    </div>
    <div class="ingredients">
      <h3>Zutaten:</h3>
      <ul>
        <li>1 tiefes Backblech</li>
        <li><strong>Für den Teig:</strong></li>
        <li>2 Gläser Kirschen (à 350 g Abtropfgewicht)</li>
        <li>400 g Mehl</li>
        <li>1 Prise Salz</li>
        <li>1 Päckchen Backpulver</li>
        <li>250 g weiche Butter</li>
        <li>200 g Zucker</li>
        <li>6 Eier</li>
        <li>1 Msp. gemahlene Vanille</li>
        <li>75 g gemahlene Mandeln</li>
        <li>6 EL Amaretto (oder ein anderer Mandellikör)</li>
        <li>150 g Amarettini</li>
        <li><strong>Für die Creme:</strong></li>
        <li>6 Blatt Gelatine</li>
        <li>120 g Puderzucker</li>
        <li>4 Eigelb</li>
        <li>300 g Mascarpone</li>
        <li>80 ml Amaretto (oder ein anderer Mandellikör)</li>
        <li>200 g Sahne</li>
      </ul>
    </div>
  </div>

  <div class="recipe">
    <div class="instructions">
      <h2>Kürbiskuchen mit Cranberries</h2>
      <p><strong>Nach 1-2 Tagen noch besser</strong></p>
      <ol>
        <li>
          Für das Kürbispüree den Kürbis halbieren, mit einem Löffel die Kerne
          entfernen und im Backofen auf Backpapier bei 180°C in etwa 40 Minuten
          weich werden lassen.
        </li>
        <li>
          Den Kürbis aus dem Ofen nehmen, entweder mit einem Löffel das weiche
          Kürbisfleisch herauslösen oder die Schale abziehen. Das Kürbisfleisch
          pürieren, 500 g Püree abmessen und für später bereitstellen.
        </li>
        <li>
          Die Pekannüsse grob hacken. Die Butter mit Zucker und Melasse in einem
          mittelgroßen Topf schmelzen. Wenn sich der Zucker aufgelöst hat, den
          Topf vom Herd nehmen und die Cranberries sowie die Pekannüsse
          einrühren. Die Nuss-Cranberry-Masse gleichmäßig auf dem Boden der
          gefetteten Springform verteilen.
        </li>
        <li>
          Für den Teig Mehl, Backpulver, Zimt, Salz und Muskat in eine Schüssel
          sieben. Den Backofen auf 150°C vorheizen.
        </li>
        <li>
          In einer Rührschüssel die Butter mit den Rührbesen des Handrührgerätes
          auf höchster Stufe schaumig schlagen, Zucker und Melasse zugeben und
          weiterschlagen. Die Eier einzeln gut einrühren, jedes Ei etwa 30
          Sekunden.
        </li>
        <li>
          Die Geschwindigkeit des Rührgeräts herunterschalten und die
          Mehlmischung einarbeiten, anschließend das Kürbispüree unterrühren und
          ganz zum Schluss die gemahlenen Haselnüsse. Den Teig in der
          vorbereiteten Form über der Pekannuss-Cranberry-Masse verteilen.
        </li>
        <li>
          Den Kürbiskuchen 60-70 Minuten auf der mittleren Schiene bei 150°C
          backen. Mit einem Holzstäbchen testen, ob der Kuchen gar ist.
        </li>
        <li>
          Den Kuchen 15 Minuten in der Form abkühlen lassen, dann den
          Springformrand mit einem Messer lösen und entfernen. Den Kürbiskuchen
          auf eine Kuchenplatte stürzen, vorsichtig den Springformboden
          entfernen und den Kuchen abkühlen lassen.
        </li>
      </ol>
    </div>
    <div class="ingredients">
      <h3>Zutaten:</h3>
      <ul>
        <li>1 Springform (26 cm), auslaufsicher</li>
        <li><strong>Für das Kürbispüree:</strong></li>
        <li>1 kg Kürbis (z. B. Hokkaido)</li>
        <li>125 g Pekannüsse (ersatzweise Walnüsse)</li>
        <li>100 g Butter</li>
        <li>100 g Zucker</li>
        <li>1 TL Melasse</li>
        <li>125 g getrocknete Cranberries</li>
        <li><strong>Für den Teig:</strong></li>
        <li>200 g Mehl</li>
        <li>2 TL Backpulver</li>
        <li>1 TL gemahlener Zimt</li>
        <li>1 Prise Salz</li>
        <li>1 Msp. gemahlene Muskatnuss</li>
        <li>250 g weiche Butter, plus Butter für die Form</li>
        <li>220 g Zucker</li>
        <li>1 EL Melasse</li>
        <li>4 Eier</li>
        <li>200 g gemahlene Haselnüsse</li>
      </ul>
    </div>
  </div>

  <div class="recipe">
    <div class="instructions">
      <h2>Apfel-Rotkohl-Kranz mit Walnüssen</h2>
      <ol>
        <li>
          Den Rotkohl in ein Sieb schütten und gut abtropfen lassen. Die Äpfel
          schälen, entkernen, in kleine Würfel schneiden und mit dem
          Zitronensaft beträufeln. Die Walnusskerne grob hacken. Alles beiseite
          stellen. Den Backofen auf 150°C vorheizen.
        </li>
        <li>
          Mehl, Natron, Salz, Zimt, Muskatpulver und Vanille mischen und in eine
          Schüssel sieben. In einer zweiten Schüssel Zucker, Eier und Öl mit dem
          Schneebesen verrühren. Die Mehlmischung nach und nach einarbeiten.
          Apfelwürfel, gehackte Nüsse und den abgetropften Rotkohl unterheben.
        </li>
        <li>
          Den Teig in die gefettete Kranzform füllen und etwa 60 Minuten auf der
          mittleren Schiene bei 150°C backen. Achtung: Der Teig ist recht
          flüssig und kann tropfen, deshalb ein Blech unter die Form stellen.
          Mit einem Holzstäbchen testen, ob der Kuchen gar ist.
        </li>
        <li>
          Den Kranz etwa 30 Minuten in der Form abkühlen lassen und anschließend
          auf eine Kuchenplatte stürzen. Sollte der Kuchen sich beim Stürzen
          nicht sofort lösen, die Form kurz mit einem nassen Handtuch abdecken,
          dann geht das ganz schnell. Den Kuchen vollständig auskühlen lassen.
        </li>
        <li>
          Für die Creme alle Zutaten in eine Schüssel geben und mit dem
          Handmixer glatt rühren. Die Creme über den abgekühlten Kranz löffeln
          und nach Belieben von allen Seiten oder nur oben damit bestreichen.
        </li>
      </ol>
    </div>
    <div class="ingredients">
      <h3>Zutaten:</h3>
      <ul>
        <li>1 Kranzform (26 cm Ø)</li>
        <li><strong>Für den Teig:</strong></li>
        <li>1 kl. Glas Apfelrotkohl (335 g Abtropfgewicht)</li>
        <li>350 g säuerliche Äpfel (z. B. Boskop)</li>
        <li>1 EL frisch gepresster Zitronensaft</li>
        <li>160 g Walnusskerne</li>
        <li>200 g Mehl</li>
        <li>2 TL Natron</li>
        <li>1 Prise Salz</li>
        <li>2 TL gemahlener Zimt</li>
        <li>1 Msp. gemahlene Muskatnuss</li>
        <li>½ TL gemahlene Vanille</li>
        <li>240 g Zucker</li>
        <li>3 Eier</li>
        <li>200 ml Walnussöl (oder Sonnenblumenöl)</li>
        <li><strong>Für die Creme:</strong></li>
        <li>400 g Doppelrahmfrischkäse (raumtemperiert)</li>
        <li>150 g Puderzucker</li>
        <li>100 g sehr weiche Butter</li>
        <li>1 EL frisch gepresster Zitronensaft</li>
        <li>Butter für die Form</li>
      </ul>
    </div>
  </div>

  <div class="recipe">
    <div class="instructions">
      <h2>Geburtstagstorte mit Orangensahne und Mandarinen</h2>
      <p><strong>Für Eilige – schnell gebacken</strong></p>
      <ol>
        <li>Die Mandarinen in ein Sieb schütten und gut abtropfen lassen.</li>
        <li>
          Den Fertigboden zweimal waagerecht durchschneiden und den ersten Boden
          auf eine Kuchenplatte legen und mit der Hälfte ring fixieren. 150 g
          Sauerrahm darauf verteilen und mit der Hälfte der Mandarinen belegen.
          Wenn Sie die Torte nicht mit Kerzen und Herzen verzieren, heben Sie
          ein paar Mandarinen für die Dekoration auf.
        </li>
        <li>
          Die Sahne leicht steif schlagen und das Getränkepulver einrieseln
          lassen. Weiterschlagen, bis die Sahne fest ist. Ein Drittel der
          Orangensahne über den Mandarinen verteilen. Den zweiten Boden
          darauflegen.
        </li>
        <li>
          Den restlichen Sauerrahm, die restlichen Mandarinen und das zweite
          Drittel der Orangensahne auf den zweiten Boden verteilen und mit dem
          letzten Boden abdecken.
        </li>
        <li>
          Den Tortenring vorsichtig entfernen und den Kuchen mit der restlichen
          Sahne umhüllen. Nach Belieben mit Kerzen und Herzen oder
          Mandarinenstücken verzieren.
        </li>
      </ol>
    </div>
    <div class="ingredients">
      <h3>Zutaten:</h3>
      <ul>
        <li>1 Tortenring (26 cm Ø)</li>
        <li><strong>Für den Teig:</strong></li>
        <li>1 Fertigboden Wiener Biskuit (26 cm Ø)</li>
        <li><strong>Für die Creme:</strong></li>
        <li>2 kl. Dosen Mandarinen (à 175 g Abtropfgewicht)</li>
        <li>300 g Sauerrahm</li>
        <li>500 g Sahne</li>
        <li>125 g Getränkepulver mit Orangengeschmack</li>
        <li><strong>Zum Verzieren:</strong></li>
        <li>Kerzen und Herzen (nach Belieben)</li>
        <li>Mandarinenstücke (nach Belieben)</li>
      </ul>
    </div>
  </div>
</body>
