<section
  id="contact"
  class="bg-black d-flex min-vh-100 justify-content-center align-items-center"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto text-center">
        <h1>{{ caption }}</h1>
        <div class="p-3">
          <form [formGroup]="contactForm">
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                formControlName="name"
                id="name"
                placeholder="Name"
              />
              <div
                class="invalid-feedback d-block"
                *ngIf="
                  contactForm.controls['name'].hasError('required') &&
                  (contactForm.controls['name'].dirty ||
                    contactForm.controls['name'].touched)
                "
              >
                Name incomplete
              </div>
            </div>
            <div class="mb-3">
              <input
                type="email"
                class="form-control"
                formControlName="email"
                id="email_address"
                placeholder="Email Address"
              />
              <div
                class="invalid-feedback d-block"
                *ngIf="
                  contactForm.controls['email'].hasError('email') &&
                  !contactForm.controls['email'].hasError('required') &&
                  (contactForm.controls['email'].dirty ||
                    contactForm.controls['email'].touched)
                "
              >
                Email Address invalid
              </div>
            </div>
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                formControlName="subject"
                id="subject"
                placeholder="Subject"
              />
              <div
                class="invalid-feedback d-block"
                *ngIf="
                  contactForm.controls['subject'].hasError('required') &&
                  (contactForm.controls['subject'].dirty ||
                    contactForm.controls['subject'].touched)
                "
              >
                Subject incomplete
              </div>
            </div>
            <div class="mb-3">
              <textarea
                class="form-control"
                formControlName="message"
                id="message"
                rows="2"
                placeholder="Message..."
              >
              </textarea>
              <div
                class="invalid-feedback d-block"
                *ngIf="
                  contactForm.controls['message'].hasError('required') &&
                  (contactForm.controls['message'].dirty ||
                    contactForm.controls['message'].touched)
                "
              >
                Message incomplete
              </div>
            </div>
          </form>
          <button
            *ngIf="!loading"
            (click)="sendContactForm()"
            class="btn btn-lg btn-outline-primary"
            [disabled]="!contactForm.valid"
          >
            <i class="fas fa-paper-plane"></i>&nbsp; Send
          </button>
          <div class="spinner-border spinner" role="status" *ngIf="loading">
            <span class="visually-hidden"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
