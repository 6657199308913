<section
  id="photography"
  class="bg-black d-flex min-vh-100 justify-content-center align-items-center"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto text-center">
        <h1>Photography</h1>
        <p>In case you could not tell, I do love pictures!</p>
        <div>
          <a
            target="_blank"
            href="https://500px.com/kurzer"
            class="btn btn-lg btn-outline-primary"
          >
            <i class="fab fa-500px"></i>&nbsp;500px
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
