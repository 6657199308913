<!-- <div
  class="parallax"
  [style.background-image]="'url(/img/' + imagePath + ')'"
></div> -->

<div>
  <img
    style="object-fit: cover; min-height: 40vh"
    sizes="(max-width: 3840px) 100vw, 3840px"
    srcset="
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_640.jpg   640w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_1090.jpg 1090w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_1424.jpg 1424w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_1707.jpg 1707w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_1966.jpg 1966w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_2192.jpg 2192w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_2427.jpg 2427w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_2605.jpg 2605w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_2798.jpg 2798w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_2970.jpg 2970w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_3145.jpg 3145w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_3302.jpg 3302w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_3463.jpg 3463w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_3622.jpg 3622w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_3779.jpg 3779w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_3829.jpg 3829w,
      ../../assets/img/Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_3840.jpg 3840w
    "
    src="Drachensee_and_Ehrwalder_Sonnenspitze_ineiyp_c_scale,w_3840.jpg"
    alt="Drachensee and Ehrwalder Sonnenspitze"
  />
</div>
