import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ContactService, ContactFormData } from '../contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  loading: boolean = false;
  caption: string = 'Get in Touch!';
  contactForm = this.fb.group({
    name: ['', [Validators.required]],
    subject: ['', [Validators.required]],
    message: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private contactService: ContactService
  ) {}

  ngOnInit(): void {
    this.loading = false;
  }

  sendContactForm() {
    const formData: ContactFormData = {
      name: this.contactForm.controls['name'].value,
      email: this.contactForm.controls['email'].value,
      subject: this.contactForm.controls['subject'].value,
      message: this.contactForm.controls['message'].value,
    };
    this.loading = true;
    this.contactService.sendContactForm(formData).subscribe((res) => {
      this.caption = 'Thanks for Your Message!';
      this.loading = false;
      this.contactForm.reset();
    });
  }
}
