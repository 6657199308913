import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

const pathsToHideComponents = [
  '/recipes',
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'KK';
  showComponents = true;

  constructor(private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      // Check if the current URL matches any path in the list
      this.showComponents = !pathsToHideComponents.some(path =>
        event.url === path || event.url.startsWith(`${path}/`)
      );
    });
  }
}
