<header>
  <div class="overlay"></div>
  <picture *ngIf="!isEvenHour">
    <img [ngClass]="{ scrolled: scrolled }" sizes="(max-width: 3840px) 100vw, 3840px" srcset="
          /assets/img/Summiting_Galenstock_via_North_Ridge_vygbwq_c_scale,w_1080.jpg 1080w,
          /assets/img/Summiting_Galenstock_via_North_Ridge_vygbwq_c_scale,w_1440.jpg 1440w,
          /assets/img/Summiting_Galenstock_via_North_Ridge_vygbwq_c_scale,w_1729.jpg 1729w,
          /assets/img/Summiting_Galenstock_via_North_Ridge_vygbwq_c_scale,w_2011.jpg 2011w,
          /assets/img/Summiting_Galenstock_via_North_Ridge_vygbwq_c_scale,w_2227.jpg 2227w,
          /assets/img/Summiting_Galenstock_via_North_Ridge_vygbwq_c_scale,w_2437.jpg 2437w,
          /assets/img/Summiting_Galenstock_via_North_Ridge_vygbwq_c_scale,w_2615.jpg 2615w,
          /assets/img/Summiting_Galenstock_via_North_Ridge_vygbwq_c_scale,w_2785.jpg 2785w,
          /assets/img/Summiting_Galenstock_via_North_Ridge_vygbwq_c_scale,w_2943.jpg 2943w,
          /assets/img/Summiting_Galenstock_via_North_Ridge_vygbwq_c_scale,w_3090.jpg 3090w,
          /assets/img/Summiting_Galenstock_via_North_Ridge_vygbwq_c_scale,w_3148.jpg 3148w,
          /assets/img/Summiting_Galenstock_via_North_Ridge_vygbwq_c_scale,w_3198.jpg 3198w,
          /assets/img/Summiting_Galenstock_via_North_Ridge_vygbwq_c_scale,w_3318.jpg 3318w,
          /assets/img/Summiting_Galenstock_via_North_Ridge_vygbwq_c_scale,w_3437.jpg 3437w,
          /assets/img/Summiting_Galenstock_via_North_Ridge_vygbwq_c_scale,w_3638.jpg 3638w,
          /assets/img/Summiting_Galenstock_via_North_Ridge_vygbwq_c_scale,w_3744.jpg 3744w,
          /assets/img/Summiting_Galenstock_via_North_Ridge_vygbwq_c_scale,w_3838.jpg 3838w,
          /assets/img/Summiting_Galenstock_via_North_Ridge_vygbwq_c_scale,w_3840.jpg 3840w"
      src="/assets/img/Summiting_Galenstock_via_North_Ridge_vygbwq_c_scale,w_3840.jpg"
      alt="Summiting Galenstock via North Ridge">
  </picture>

  <picture *ngIf="isEvenHour">
    <img [ngClass]="{ scrolled: scrolled }" sizes="(max-width: 3840px) 100vw, 3840px" srcset="
  /assets/img/Majecstic_Zinalrothorn_Towering_above_Triftglacier_vref2k_c_scale,w_1080.jpg 1080w,
  /assets/img/Majecstic_Zinalrothorn_Towering_above_Triftglacier_vref2k_c_scale,w_1407.jpg 1407w,
  /assets/img/Majecstic_Zinalrothorn_Towering_above_Triftglacier_vref2k_c_scale,w_1698.jpg 1698w,
  /assets/img/Majecstic_Zinalrothorn_Towering_above_Triftglacier_vref2k_c_scale,w_1947.jpg 1947w,
  /assets/img/Majecstic_Zinalrothorn_Towering_above_Triftglacier_vref2k_c_scale,w_1977.jpg 1977w,
  /assets/img/Majecstic_Zinalrothorn_Towering_above_Triftglacier_vref2k_c_scale,w_2369.jpg 2369w,
  /assets/img/Majecstic_Zinalrothorn_Towering_above_Triftglacier_vref2k_c_scale,w_2155.jpg 2155w,
  /assets/img/Majecstic_Zinalrothorn_Towering_above_Triftglacier_vref2k_c_scale,w_2726.jpg 2726w,
  /assets/img/Majecstic_Zinalrothorn_Towering_above_Triftglacier_vref2k_c_scale,w_2897.jpg 2897w,
  /assets/img/Majecstic_Zinalrothorn_Towering_above_Triftglacier_vref2k_c_scale,w_3072.jpg 3072w,
  /assets/img/Majecstic_Zinalrothorn_Towering_above_Triftglacier_vref2k_c_scale,w_3126.jpg 3126w,
  /assets/img/Majecstic_Zinalrothorn_Towering_above_Triftglacier_vref2k_c_scale,w_3192.jpg 3192w,
  /assets/img/Majecstic_Zinalrothorn_Towering_above_Triftglacier_vref2k_c_scale,w_3238.jpg 3238w,
  /assets/img/Majecstic_Zinalrothorn_Towering_above_Triftglacier_vref2k_c_scale,w_3374.jpg 3374w,
  /assets/img/Majecstic_Zinalrothorn_Towering_above_Triftglacier_vref2k_c_scale,w_3563.jpg 3563w,
  /assets/img/Majecstic_Zinalrothorn_Towering_above_Triftglacier_vref2k_c_scale,w_3693.jpg 3693w,
  /assets/img/Majecstic_Zinalrothorn_Towering_above_Triftglacier_vref2k_c_scale,w_3812.jpg 3812w,
  /assets/img/Majecstic_Zinalrothorn_Towering_above_Triftglacier_vref2k_c_scale,w_3837.jpg 3837w,
  /assets/img/Majecstic_Zinalrothorn_Towering_above_Triftglacier_vref2k_c_scale,w_3840.jpg 3840w"
      src="/assets/img/Majecstic_Zinalrothorn_Towering_above_Triftglacier_vref2k_c_scale,w_3840.jpg"
      alt="Majecstic Zinalrothorn Towering above Triftglacier">
  </picture>
</header>
