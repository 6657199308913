<section
  id="about"
  class="bg-black d-flex min-vh-100 justify-content-center align-items-center"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto text-center">
        <h1>About</h1>
        <picture>
          <img
            appIntersectionObserver
            intersectionClass="img-about m-3"
            nonIntersectionClass="img-about grayscale m-3"
            class=""
            sizes="(max-width: 1000px) 100vw, 1000px"
            srcset="
              ./../assets/img/Karl_Kurzer_xgim1g_c_scale,w_569.jpg   569w,
              ./../assets/img/Karl_Kurzer_xgim1g_c_scale,w_836.jpg   836w,
              ./../assets/img/Karl_Kurzer_xgim1g_c_scale,w_1000.jpg 1000w
            "
            src="./../assets/img/Karl_Kurzer_xgim1g_c_scale,w_1000.jpg"
            alt="Karl Kurzer"
          />
        </picture>
        <p>Hello, I am Karl! I love running, mountaineering, and AI.</p>
      </div>
    </div>
  </div>
</section>