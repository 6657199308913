import {
  Component, OnInit, ElementRef,
  ViewChild,
  HostListener,
} from '@angular/core';

@Component({
  selector: 'app-picture-cover',
  templateUrl: './picture-cover.component.html',
  styleUrls: ['./picture-cover.component.scss']
})
export class PictureCoverComponent implements OnInit {
  //@ts-ignore
  @ViewChild('pictureCover') pictureCover: ElementRef;
  scrolled: boolean = false;
  isEvenHour: boolean = false;

  constructor() { }

  ngOnInit(): void {
    const currentHour = new Date().getHours();
    this.isEvenHour = currentHour % 2 === 0;
  }

  @HostListener('window:scroll') onWindowScroll() {
    this.scrolled = window.pageYOffset > 0;
  }
}