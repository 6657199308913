<header>
  <div class="overlay"></div>
  <video
    #videoCover
    [ngClass]="{ scrolled: scrolled }"
    playsinline="playsinline"
    autoplay="autoplay"
    muted="muted"
  >
    <source src="assets/mov/output.webm" type="video/webm" />
    <source src="assets/mov/output.mp4" type="video/mp4" />
  </video>
  <!-- <div class="container h-100">
    <div class="d-flex h-100 text-center align-items-center">
      <div class="w-100 text-white">
        <h1 class="display-3">KK</h1>
        <p class="lead mb-0"></p>
      </div>
    </div>
  </div> -->
</header>
