import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appIntersectionObserver]',
  exportAs: 'intersection',
})
export class IntersectionObserverDirective {
  @Input() intersectionClass: string = '';
  @Input() nonIntersectionClass: string = '';
  public isIntersecting: boolean;

  private elementRef: ElementRef;
  private observer: IntersectionObserver | null;

  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
    this.observer = null;
    this.isIntersecting = false;
  }

  public ngOnDestroy(): void {
    this.observer?.disconnect();
    this.observer = null;
  }

  public ngOnInit(): void {
    this.observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        this.isIntersecting = entries[0].isIntersecting;
        if (this.isIntersecting) {
          entries[0].target.className = this.intersectionClass;
        } else {
          entries[0].target.className = this.nonIntersectionClass;
        }
      },
      {
        rootMargin: '0%',
        threshold: 1.0,
      }
    );
    this.observer.observe(this.elementRef.nativeElement);
  }
}
