import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ParallaxComponent } from './parallax/parallax.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { VideoCoverComponent } from './video-cover/video-cover.component';
import { AboutComponent } from './about/about.component';
import { ResearchComponent } from './research/research.component';
import { ConsultingComponent } from './consulting/consulting.component';
import { PhotographyComponent } from './photography/photography.component';
import { ContactComponent } from './contact/contact.component';
import { IntersectionObserverDirective } from './intersection-observer.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PictureCoverComponent } from './picture-cover/picture-cover.component';
import { RecipesComponent } from './recipes/recipes.component';

@NgModule({
  declarations: [
    AppComponent,
    ParallaxComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    VideoCoverComponent,
    AboutComponent,
    ResearchComponent,
    ConsultingComponent,
    PhotographyComponent,
    ContactComponent,
    IntersectionObserverDirective,
    PictureCoverComponent,
    RecipesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
