import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener,
} from '@angular/core';

@Component({
  selector: 'app-video-cover',
  templateUrl: './video-cover.component.html',
  styleUrls: ['./video-cover.component.scss'],
})
export class VideoCoverComponent implements OnInit {
  //@ts-ignore
  @ViewChild('videoCover') videoCover: ElementRef;
  scrolled: boolean = false;
  constructor() {}

  ngOnInit(): void {}

  @HostListener('window:scroll') onWindowScroll() {
    this.scrolled = window.pageYOffset > 0;
    // if (!window.pageYOffset) this.videoCover.nativeElement.play();
  }
}
